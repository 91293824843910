/*引入Vue框架*/
import Vue from 'vue';
/*引入资源请求插件*/
import axios from 'axios';
import router from '../Router';
import { Message } from "element-ui";
import store from '../store/index.js';
import qs from 'qs';
import 'element-ui/lib/theme-chalk/message.css'

axios.defaults.timeout = 5000;

//http request 拦截器
axios.interceptors.request.use(
  config => {
    // config.data = JSON.stringify(config.data); //这句拦截会把所有的请求对象转为JSON字符串
    config.headers = {
      'Content-Type':'application/x-www-form-urlencoded',
      //'Authorization':store.getters.getToken
    }
    // config.params = {'from':baidu } // url?from=baidu
		
		

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    if(response.data.CodeId == -1){ //登陆页面永不返回-1
        router.push('/login')
        return
    }
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
function get(url,params={}){
  return new Promise((resolve,reject) => {
    axios.get(url,{
      params:params
    })
    .then(response => {
      resolve(response.data);
    })
    .catch(err => {
      console.log('出错码',err)
      Message({
          showClose: true,
          message: '网络层出错，请联系技术核查',
          type: "error"
         });
      reject(err)
    })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url,data = {}){
	
		data["AppId"]= 'sk-yitong-pc';
		data["Token"]= store.getters.getToken;
		data["Version"]= "1.3";
		
		let postConfig = {timeout: 5000}
		if(data["AjaxTimeout"] != undefined && data["AjaxTimeout"] > 0){
			postConfig.timeout = data["AjaxTimeout"]
		}
		
		//对MOCK唯一网址支持
		url += '/'+data.Service+'/'+data.Class+'/'+data.Action
		
		delete data.Service
		delete data.Class
		delete data.Action
		
		//对MOCK唯一网址支持END
	
   return new Promise((resolve,reject) => {
     axios.post(url,qs.stringify(data),postConfig)
          .then(response => {
            resolve(response.data);
          },err => {
          Message({
          showClose: true,
          message: '网络层出错，请联系技术核查',
          type: "error"
         });
            reject(err)
          })
   })
 }

  /**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url,data = {}){
	
	data["AppCode"]= store.getters.getAppCode;
	
  return new Promise((resolve,reject) => {
    axios.put(url,qs.stringify(data))
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

 /**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.patch(url,qs.stringify(data))
         .then(response => {
           resolve(response.data);
         },err => {
           reject(err)
         })
  })
}

/*使用axios插件*/
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$put = put;
Vue.prototype.$patch = patch;

export default({
});