export default {
  state: {
    Carts: [], //购物车
  },
  mutations: { // store.commit('increment')
    SCart(state, str) {
      state.Carts = str
    },
	SCartPush(state, str) {
	  state.Carts.push(str)
	},
  }, 
  actions: { // store.dispatch('incrementFormActions')
    setCart (context, str) {
      context.commit('SCarts', str)
    },
	setCartPush (context, str) {
	  context.commit('SCartPush', str)
	},
  },
  getters: {
    getCarts: state => {
      return state.Carts
    },
  }
}
