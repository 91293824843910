<template>
  <div id="app">

		<router-view/>
	
  </div>
  
</template>

<script>
export default {
  name: 'app',
  data() {
      return {
      }
  },
  computed: {
	
  },
  components: {
  },
  created() {
	  
	  this.ss()
	  
  },
  methods:{
	  ss(){
			
			//加载账户状态
			let LoginDataStr = localStorage.getItem('LoginData')
			if(LoginDataStr != null && LoginDataStr.length > 0){
				
				let loginDataObj = JSON.parse(LoginDataStr)
				this.$store.dispatch('setToken',loginDataObj.Token)
				this.$store.dispatch('setUserName',loginDataObj.Account)
				this.$store.dispatch('setIdentity',loginDataObj.Identity)
				this.$store.dispatch('setIdentityName',loginDataObj.IdentityName)

			} 
		  
		  if(this.$store.getters.getToken == ''){
		  		  
		  		  let centerRouterNames = [
		  		  	'Index',
		  		  ]
		  		  let temp = centerRouterNames.find(t => t == this.$route.name)
		  		  if(temp == undefined){
		  		  	this.$router.push('/login')
		  		  }
		  		  
		  }
	  }
  }
}
</script>

<style>
	body,html{
		margin: 0px;
		padding: 0px;
		font-size: 14px;
		color: #666666;
		background-color: #f2f2f2;
		height: 100%;
	}
	#app{
		height: 100%;
	}
	ul{
		padding: 0px;
	}
	li{
		list-style: none;
	}
	a{
		text-decoration: none;
	}
	em{
		font-style: normal;
	}
	ul{
		margin: 0px;
		padding: 0px;
	}
	.Wrap{
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.BGWhite{
		background-color: #FFFFFF;
	}
	.MarginTop20{
		margin-top: 20px;
	}
	.MarginTop10{
		margin-top: 10px;
	}
	.MarginBottom10{
		margin-bottom: 10px;
	}
	.PaddingBottom20{
		padding-bottom: 20px;
	}
	.Padding20{
		padding: 20px;
	}
	.Page{
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 20px 0px;
	}
</style>
