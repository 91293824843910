export default {
  state: {
    Code: 'wujincaicode', //弃用
    Token: '',
    Identity: 'buyer',
	IdentityName: '消费者',
  },
  mutations: { // store.commit('increment')
    SCode(state, str) {
      state.Code = str
    },
    SToken(state, str) {
      state.Token = str
    },
    SUserName(state, str) {
      state.UserName = str
    },
	SIdentity(state, str) {
	  state.Identity = str
	},
	SIdentityName(state, str) {
	  state.IdentityName = str
	},
  }, 
  actions: { // store.dispatch('incrementFormActions')
    setCode (context, str) {
      context.commit('SCode', str)
    },
    setToken (context, str) {
      context.commit('SToken', str)
    },
    setUserName (context, str) {
      context.commit('SUserName', str)
    },
	setIdentity (context, str) {
	  context.commit('SIdentity', str)
	},
	setIdentityName (context, str) {
	  context.commit('SIdentityName', str)
	},
  },
  getters: {
    getCode: state => {
      return state.Code
    },
    getToken: state => {
      return state.Token
    },
    getUserName: state => {
      return state.UserName
    },
	getIdentity: state => {
	  return state.Identity
	},
	getIdentityName: state => {
	  return state.IdentityName
	},
  }
}
