//公共方法
import Vue from 'vue';


function Jump(_url,_para = {}){
	
	this.$router.push({  //核心语句
		path:_url,   //跳转的路径
		query:_para
	  })
	
}

function Jump2(_url){
	
	location.href = _url
	
}

Vue.prototype.$Jump = Jump
Vue.prototype.$Jump2 = Jump2
export default({
})