import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
export default new Router({
  linkActiveClass: 'active', // 路由active状态
  routes: [
    {
		name:'Home',path: '/',meta: { title: '管理中心' },
		component: resolve => require(['@/pages/index/index'], resolve),
    },
    {
		name:'Login',
      path: '/login',meta: { title: '登录' },
      component: resolve => require(['@/pages/user/login'], resolve)
    },
    {
		name:'Do',
		path: '/do',meta: { title: '管理中心' },
		component: resolve => require(['@/pages/my/dispatch'], resolve),
		children:[
			{
				name:'UserList',
				path: 'user/user_list',meta: { title: '用户列表' },
				component: resolve => require(['@/pages/user/user_list'], resolve),
			},
			{
				name:'UserInfo',
				path: 'user/user_info/:Id',meta: { title: '用户详请' },
				component: resolve => require(['@/pages/user/user_info'], resolve),
			},
			{
				name:'BrandList',
				path: 'brand/brand_list',meta: { title: '品牌列表' },
				component: resolve => require(['@/pages/brand/brand_list'], resolve),
			},
			{
				name:'BrandInfo',
				path: 'brand/brand_info/:Id',meta: { title: '品牌管理' },
				component: resolve => require(['@/pages/brand/brand_info'], resolve),
			},
			{
				name:'ShopList',
				path: 'shop/shop_list',meta: { title: '店铺列表' },
				component: resolve => require(['@/pages/shop/shop_list'], resolve),
			},
			{
				name:'ShopInfo',
				path: 'shop/shop_info/:Id',meta: { title: '店铺详情' },
				component: resolve => require(['@/pages/shop/shop_info'], resolve),
			},
			{
				name:'ShopApplyList',
				path: 'shop/shop_apply_list',meta: { title: '店铺申请列表' },
				component: resolve => require(['@/pages/shop/shop_apply_list'], resolve),
			},
			{
				name:'ShopApplyInfo',
				path: 'shop/shop_apply_info/:Id',meta: { title: '店铺申请详情' },
				component: resolve => require(['@/pages/shop/shop_apply_info'], resolve),
			},
			{
				name:'ShopCategoryList',
				path: 'shop/shop_list',meta: { title: '店铺分类列表' },
				component: resolve => require(['@/pages/shop/shop_category_list'], resolve),
			},
			{
				name:'ShopCategoryInfo',
				path: 'shop/shop_info/:Id',meta: { title: '店铺分类详情' },
				component: resolve => require(['@/pages/shop/shop_category_info'], resolve),
			},
			{
				name:'GoodsCategoryList',
				path: 'goods/goods_category_list',meta: { title: '商品分类列表' },
				component: resolve => require(['@/pages/goods/goods_category_list'], resolve),
			},
			{
				name:'GoodsCategoryInfo',
				path: 'goods/goods_category_info/:Id',meta: { title: '商品分类详情' },
				component: resolve => require(['@/pages/goods/goods_category_info'], resolve),
			},
			{
				name:'GoodsCategoryCache',
				path: 'goods/goods_category_cache',meta: { title: '商品分类缓存' },
				component: resolve => require(['@/pages/goods/goods_category_cache'], resolve),
			},
			{
				name:'GoodsCategoryAddChildren',
				path: 'goods/goods_category_addchildren/:Id',meta: { title: '商品子分类批量添加' },
				component: resolve => require(['@/pages/goods/goods_category_addchildren'], resolve),
			},
			{
				name:'System',
				path: 'setting/system',meta: { title: '系统配置' },
				component: resolve => require(['@/pages/setting/system'], resolve),
			},
			{
				name:'SettingInfo',
				path: 'setting/setting_info/:Key',meta: { title: '配置详情' },
				component: resolve => require(['@/pages/setting/setting_info'], resolve),
			},
			{
				name:'OrderList',
				path: 'order/order_list',meta: { title: '订单列表' },
				component: resolve => require(['@/pages/order/order_list'], resolve),
			},
			{
				name:'OrderDetail',
				path: 'order/order_detail/:Id',meta: { title: '订单详情' },
				component: resolve => require(['@/pages/order/order_detail'], resolve),
			},
			{
				name:'GoodsList',
				path: 'goods/goods_list',meta: { title: '商品列表' },
				component: resolve => require(['@/pages/goods/goods_list'], resolve),
			},
			{
				name:'GoodsInfo',
				path: 'goods/goods_info/:Id',meta: { title: '商品详情' },
				component: resolve => require(['@/pages/goods/goods_info'], resolve),
			},
			{
				name:'CuponList',
				path: 'coupon/coupon_list',meta: { title: '优惠券列表' },
				component: resolve => require(['@/pages/coupon/coupon_list'], resolve),
			},
			{
				name:'CouponInfo',
				path: 'coupon/coupon_info/:Id',meta: { title: '优惠券详情' },
				component: resolve => require(['@/pages/coupon/coupon_info'], resolve),
			},
			{
				name:'GoodsComment',
				path: 'goods_comment/list',meta: { title: '商品评价' },
				component: resolve => require(['@/pages/goods_comment/goods_comment_list'], resolve),
			},
			{
				name:'KeywordsList',
				path: 'keywords/list',meta: { title: '用户关键词列表' },
				component: resolve => require(['@/pages/keywords/list'], resolve),
			},
			{
				name:'KeywordsInfo',
				path: 'keywords/info/:Id',meta: { title: '用户关键词' },
				component: resolve => require(['@/pages/keywords/info'], resolve),
			},
			{
				name:'ExpressCompanyList',
				path: 'express_company/list',meta: { title: '快递公司列表' },
				component: resolve => require(['@/pages/express_company/express_company_list'], resolve),
			},
			{
				name:'ExpressCompanyInfo',
				path: 'express_company/info/:Id',meta: { title: '快递公司详请' },
				component: resolve => require(['@/pages/express_company/express_company_info'], resolve),
			},
			{
				name:'FinanceCount',
				path: 'finance/count',meta: { title: '平台财务管理' },
				component: resolve => require(['@/pages/finance/count'], resolve),
			},
			{
				name:'RechargeList',
				path: 'finance/recharge_list',meta: { title: '充值记录' },
				component: resolve => require(['@/pages/finance/recharge_list'], resolve),
			},
			{
				name:'RechargeInfo',
				path: 'finance/recharge_info/:Id',meta: { title: '充值详情' },
				component: resolve => require(['@/pages/finance/recharge_info'], resolve),
			},
			{
				name:'TradeList',
				path: 'finance/trade_list',meta: { title: '资金明细' },
				component: resolve => require(['@/pages/finance/trade_list'], resolve),
			},
			{
				name:'TradeInfo',
				path: 'finance/trade_info/:Id',meta: { title: '流水详情' },
				component: resolve => require(['@/pages/finance/trade_info'], resolve),
			},
			{
				name:'WithdrawList',
				path: 'finance/withdraw_list',meta: { title: '提现记录' },
				component: resolve => require(['@/pages/finance/withdraw_list'], resolve),
			},
			{
				name:'WithdrawInfo',
				path: 'finance/withdraw_info/:Id',meta: { title: '提现详情' },
				component: resolve => require(['@/pages/finance/withdraw_info'], resolve),
			},
			{
				name:'WithdrawPayAccountList',
				path: 'finance/withdraw_pay_account_list',meta: { title: '提现打款账号' },
				component: resolve => require(['@/pages/finance/withdraw_pay_account_list'], resolve),
			},
			{
				name:'WithdrawPayAccountInfo',
				path: 'finance/withdraw_pay_account_info/:Id',meta: { title: '打款账号详情' },
				component: resolve => require(['@/pages/finance/withdraw_pay_account_info'], resolve),
			},
			{
				name:'NewsList',
				path: 'news/list',meta: { title: '新闻管理' },
				component: resolve => require(['@/pages/news/news_list'], resolve),
			},
			{
				name:'NewsInfo',
				path: 'news/info/:Id',meta: { title: '新闻详情' },
				component: resolve => require(['@/pages/news/news_info'], resolve),
			},
			{
				name:'NewsCategoryList',
				path: 'news/category_list',meta: { title: '新闻分类管理' },
				component: resolve => require(['@/pages/news/news_category_list'], resolve),
			},
			{
				name:'NewsCategoryInfo',
				path: 'news/category_info/:Id',meta: { title: '新闻分类详情' },
				component: resolve => require(['@/pages/news/news_category_info'], resolve),
			},
			{
				name:'HelpList',
				path: 'help/list',meta: { title: '帮助管理' },
				component: resolve => require(['@/pages/help/help_list'], resolve),
			},
			{
				name:'HelpInfo',
				path: 'help/info/:Id',meta: { title: '帮助详情' },
				component: resolve => require(['@/pages/help/help_info'], resolve),
			},
			{
				name:'HelpCategoryList',
				path: 'help/category_list',meta: { title: '帮助分类管理' },
				component: resolve => require(['@/pages/help/help_category_list'], resolve),
			},
			{
				name:'HelpCategoryInfo',
				path: 'help/category_info/:Id',meta: { title: '帮助分类详情' },
				component: resolve => require(['@/pages/help/help_category_info'], resolve),
			},
			{
				name:'WorkOrderList',
				path: 'workorder/list',meta: { title: '工单管理' },
				component: resolve => require(['@/pages/workorder/list'], resolve),
			},
			{
				name:'WorkOrderInfo',
				path: 'workorder/info/:Id',meta: { title: '工单详情' },
				component: resolve => require(['@/pages/workorder/info'], resolve),
			},
			{
				name:'WorkOrderCategoryList',
				path: 'workorder/category_list',meta: { title: '工单分类管理' },
				component: resolve => require(['@/pages/workorder/category_list'], resolve),
			},
			{
				name:'WorkOrderCategoryInfo',
				path: 'workorder/category_info/:Id',meta: { title: '工单分类详情' },
				component: resolve => require(['@/pages/workorder/category_info'], resolve),
			},
			{
				name:'AdList',
				path: 'ad/list',meta: { title: '广告管理' },
				component: resolve => require(['@/pages/ad/ad_list'], resolve),
			},
			{
				name:'AdInfo',
				path: 'ad/info/:Id',meta: { title: '广告详情' },
				component: resolve => require(['@/pages/ad/ad_info'], resolve),
			},
			{
				name:'MenuList',
				path: 'menu/list',meta: { title: '商城导航管理' },
				component: resolve => require(['@/pages/menu/menu_list'], resolve),
			},
			{
				name:'MenuInfo',
				path: 'menu/info/:Id',meta: { title: '商城导航详情' },
				component: resolve => require(['@/pages/menu/menu_info'], resolve),
			},
			{
				name:'GoodsList',
				path: 'goods/list',meta: { title: '商品列表' },
				component: resolve => require(['@/pages/goods/goods_list'], resolve),
			},
			{
				name:'ApiAccountList',
				path: 'api_account/list',meta: { title: '第三方接口账户管理' },
				component: resolve => require(['@/pages/api_account/list'], resolve),
			},
			{
				name:'AdInfo',
				path: 'api_account/info/:Id',meta: { title: '第三方接口账户详情' },
				component: resolve => require(['@/pages/api_account/info'], resolve),
			},
			{
				name:'SMSIndex',
				path: 'message/sms',meta: { title: '短信通知' },
				component: resolve => require(['@/pages/message/sms_index'], resolve),
			},
			{
				name:'GZHList',
				path: 'message/gzh',meta: { title: '公众号消息' },
				component: resolve => require(['@/pages/message/gzhlist'], resolve),
			},
			{
				name:'AppMessageList',
				path: 'message/app',meta: { title: '站内消息' },
				component: resolve => require(['@/pages/message/applist'], resolve),
			},
		]
    }
  ]
})
