export default {
  state: {
    ApiHost: 'https://api.wojiasx.com', 
		//https://api.wojiasx.com
		//http://localhost:29100
    AppId: '0e0949ee-2b75-4328-9ff7-38d3983bc1ed', //应用的唯一ID
	LoginDirect:'',//登录后的定向网址
	AppName:'我嘉生鲜',//应用名称
	BaseSnApiAccount: '275fbdbe-3844-4cf4-ad13-a8f8020b5d0b', //苏宁接口总账户
	MyqApiAccount: '6d16e57c-d6db-4121-90de-64943dbd1bce', //猫有券账户
	TbkApiAccount: 'db460272-f8ce-4cc4-b0a7-3db27b386c58', //淘宝客账户
  },
  mutations: { // store.commit('increment')
    SApiHost (state, str) {
      state.Code = str
    },
    SAppId (state, str) {
      state.AppId = str
    },
	SetLoginDirect (state, str) {
	  state.LoginDirect = str
	},
	SetAppName (state, str) {
	  state.AppName = str
	},
	SetBaseSnApiAccount (state, str) {
	  state.BaseSnApiAccount = str
	},
	SetMyqApiAccount (state, str) {
	  state.MyqApiAccount = str
	},
	SetTbkApiAccount (state, str) {
	  state.TbkApiAccount = str
	}
  },
  actions: { // store.dispatch('incrementFormActions')
    setApiHost (context, str) {
      context.commit('SApiHost', str)
    },
    setAppId (context, str) {
      context.commit('SAppId', str)
    },
		setLoginDirect(context, str) {
		  context.commit('SetLoginDirect', str)
		},
		setAppName(context, str) {
		  context.commit('SetAppName', str)
		},
		setBaseSnApiAccount(context, str) {
		  context.commit('SetBaseSnApiAccount', str)
		},
		setMyqApiAccount(context, str) {
		  context.commit('SetMyqApiAccount', str)
		},
		setTbkApiAccount(context, str) {
		  context.commit('SetTbkApiAccount', str)
		},
  },
  getters: {
    getApiHost: state => {
      return state.ApiHost
    },
    getAppId: state => {
      return state.AppId
    },
    getLoginDirect: state => {
      return state.LoginDirect
    },
    getAppName: state => {
      return state.AppName
    },
	getBaseSnApiAccount: state => {
	  return state.BaseSnApiAccount
	},
	getMyqApiAccount: state => {
	  return state.MyqApiAccount
	},
	getTbkApiAccount: state => {
	  return state.TbkApiAccount
	}
  }
}